import { Row } from '@christiankaindl/lyts'
import LawIcon from '@/public/assets/law.icon.svg'
import Text from './Text/Text'
import { theme } from '@/styles/theme.css'

interface Props {
  text: string
  size?: 'small' | 'default'
}

export default function LabelWithIcon ({ size, text }: Props) {
  return (
    <Row tabIndex={0} gap={0.25} style={{ color: theme.colors.text.brand, cursor: 'default' }}>
      <LawIcon width={size === 'small' ? 16 : 20} />
      <Text size={size} color={theme.colors.text.brand} weight={600} maxLines={1}>{text}</Text>
    </Row>
  )
}
