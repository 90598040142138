'use client'

import { Stack } from '@christiankaindl/lyts'
import { type ReactNode, useState, useEffect, useTransition } from 'react'
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/Tooltip/Tooltip'
import Text from '@/components/Text/Text'
import { type TooltipProps } from '@ariakit/react'
import LabelWithIcon from '../LabelWithIcon'
import ErrorBoundary from '../ErrorBoundary'
import Placeholder from '../Placeholder/Placeholder'
import { retrievePreviewAction } from './retrievePreviewAction'
interface ClausePreviewProps {
  children: ReactNode
  lawId: string
  clauseId: string
  documentType?: string
  render?: TooltipProps['render']
  id?: string
}

export default function ClauseHoverPreview ({ children, lawId, clauseId, render, id }: ClausePreviewProps) {
  const [isHovered, setIsHovered] = useState(false)
  const [data, setData] = useState<any>(null)
  const [isPending, startTransition] = useTransition()
  useEffect(() => {
    if (data) return

    if (isHovered && id) {
      startTransition(async () => {
        const _data = await retrievePreviewAction(id)
        console.log({ _data })
        setData(_data)
      })
    }
  }, [isHovered])

  return (
    <Tooltip timeout={100} placement='bottom-start'>
      <TooltipTrigger render={render} onPointerOver={() => { setIsHovered(true) }}>
        {children}
      </TooltipTrigger>
      <TooltipContent gutter={3} style={{ maxWidth: '24em', padding: 15, contain: 'layout', textAlign: 'left', borderRadius: 12, width: '100%' }}>
        {isPending && (
          <Stack gap={0.5}>
            <Placeholder type='text' />
            <Placeholder type='text' />
          </Stack>
        )}
        {data && (
          <ErrorBoundary dataInfo={() => JSON.stringify({ lawId, clauseId }, null, 2)}>
            <Stack gap={0.5}>
              <LabelWithIcon text={data.abbreviation ?? data.lawTitle} size='small' />
              <Text maxLines={2} weight='bold' style={{ lineHeight: 1.3 }}>
                {data.enumeration ? <>{data.enumeration}&ensp;</> : ''}{data.title}
              </Text>
              {(data.content ?? data.snippet) && (
                <Text maxLines={4} color='secondary' style={{ lineHeight: 1.3 }}>
                  {data.para ? `Abs ${data.para as string}: ` : null}{data.content ?? data.snippet}
                </Text>
              )}
            </Stack>
          </ErrorBoundary>
        )}
      </TooltipContent>
    </Tooltip>
  )
}
