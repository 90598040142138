'use client'

import * as styles from '@/app/suche/suche.css'
import Link from 'next/link'
import { Row, Stack } from '@christiankaindl/lyts'
import Heading from '@/components/Heading/Heading'
import { card } from '@/styles/theme.css'
import Text from '@/components/Text/Text'
import { formatDate, getDecisionUrl, getUrl, urlify } from '@/lib/utils'
import LawIcon from '@/public/assets/law.icon.svg'
import { Scale } from 'lucide-react'
import { type INode } from '@/lib/ris-types'
import { Fragment } from 'react'
import type { DocumentSearchResponse } from '@/lib/search.server'

export type IResult = {
  /**  */
  documentType: 'clause'
  lawType: string
  lawTitle: string
  apiPath: string
  norm?: {
    title?: string
    abbreviation?: string
    abbrNr?: number
  }
  abbreviation?: string
  abbrNr: undefined
  title?: string
  type: string
  normId: string
  enumeration: string
  objectID?: string
  _highlightResult?: any
  clauses?: any[]
} | {
  /**  */
  documentType: 'law'
  lawTitle: string
  apiPath: string
  lawType: string
  title?: string
  normId: string
  abbreviation?: string
  type: string
  abbrNr?: number
  objectID?: string
  _highlightResult?: any
  clauses?: any[]
} | IRechtssatzResult | IEntscheidungResult

export interface RechtssatzNorm {
  normId?: string
  abbreviation?: string
  type: string
  /** The full string identifier. E.g. "StPO §281 Abs1 Z3" */
  norm: string
  enumeration?: string
  abbrNr?: number
  title?: string
}
interface IRechtssatzResult {
  documentType: 'rechtssatz'
  Rechtssatznummer?: string
  normId: string
  norms: RechtssatzNorm[]
  /** Used to construct the URL paths. E.g. "Justiz" */
  type: string
  objectID: string
  _snippetResult?: any
  entscheidungenCount: number
  Gericht: string | string[]
  /** "Entscheidungsdatum"; For Rechtssaetze this is just the latest linked Entscheidung's date */
  decisionDate: string
  /** "Veroeffentlicht"; Date from which on it was available in RIS. Only use for Rechtssaetze */
  apiPath: string
  publishDate: string
  children?: INode[]
}
interface IEntscheidungResult {
  /**  */
  documentType: 'decision'
  Geschaeftszahl?: string
  normId: string
  /** Used to construct the URL paths. E.g. "Justiz" */
  type: string
  objectID?: string
  _snippetResult?: any
  apiPath: string
  children?: INode[]
  rechtssaetze?: Array<{
    Applikation: string
    norms: RechtssatzNorm[]
    Entscheidungsdatum: string
    Rechtssatznummer: string
    normId: string
    Gericht: string
    Veroeffentlicht: string
    content: string
  }>
  rechtssaetzeCount?: number
  Gericht: string | string[]
  /** "Entscheidungsdatum"; For Rechtssaetze this is just the latest linked Entscheidung's date */
  decisionDate: string
  /** "Veroeffentlicht"; Date from which on it was available in RIS. Only use for Rechtssaetze */
  publishDate: string
}

type DocumentGroupResults = NonNullable<DocumentSearchResponse['grouped_hits']>[0]

interface ResultProps {
  result: DocumentGroupResults
  // resultNb?: number
  // sendEvent: SendEventForHits | undefined
}
const mapping = {
  bvg: 'Bundesverfassungsgesetz',
  bg: 'Bundesgesetz',
  v: 'Verordnung',
  k: 'Kundmachung',
  entschl: 'Entschließung des Bundespräsidenten',
}
/**
 * Displays a search result.
 *
 * Include the `sendEvent` prop to enable Algolia Insights.
 */
export function Result ({ result }: ResultProps): JSX.Element {
  const documentType = result.hits?.[0].document.documentType
  if (documentType === 'decision') {
    return (
      <EntscheidungResult result={result} />
    )
  } else if (documentType === 'rechtssatz') {
    return (
      <RechtssatzResult result={result} />
    )
  } else {
    return (
      <LawResult result={result} />
    )
  }
}

export function LawResult ({ result }: { result: DocumentGroupResults }) {
  const isFullNormMatch = false
  // const isFullNormMatch = result._highlightResult?.abbreviation?.matchLevel === 'full' && resultNb === 0

  // console.log('here 2?')

  // if (!result.hits) {
  //   return (
  //     <li className={styles.resultContainer}>
  //       <Row asChild gap='15px' className={styles.resultItem}>
  //         <Link href={path}>
  //           <div
  //             style={{
  //               height: 42,
  //               width: 42,
  //               display: 'flex',
  //               justifyContent: 'center',
  //               alignItems: 'center',
  //               backgroundColor: 'rgba(228, 177, 95, 0.2)',
  //               borderRadius: 99,
  //               marginLeft: -6,
  //               marginTop: -6,
  //               marginBottom: -6,
  //               color: '#A48757',
  //               flexShrink: 0,
  //             }}
  //           >
  //             <LawIcon width={20} />
  //           </div>
  //           <Stack gap={0}>
  //             <Text style={{ flexGrow: 1, lineHeight: 1.3 }} weight={600} size={isFullNormMatch ? 'large' : '1.075em'} maxLines={2}>
  //               {result.title}
  //             </Text>
  //             <Text style={{ marginTop: 0 }} color='secondary'>{result.abbreviation && `${result.abbreviation} · `}{mapping[result.lawType?.toLowerCase()] ?? result.lawType}</Text>
  //           </Stack>
  //         </Link>
  //       </Row>
  //     </li>
  //   )
  // }

  return (
    <Stack gap='1px' className={card} style={{ padding: 6 }}>
      {result.hits && result.hits.length > 0 && result.hits?.map(({ document: hit }, index) => {
        if (!hit.lawType) return null

        // console.log('here 1?', hit.lawType, hit.abbreviation, hit.normId, hit.documentType)
        const path = getUrl(hit.lawType, { norm: hit.abbreviation ?? hit.normId, abbrNr: hit.abbrNr })

        if (hit.documentType === 'law') {
          // Full norm match
          return (
            <Fragment key={hit.id}>
              <Row asChild gap='15px' className={styles.resultItem}>
                <Link
                  href={path}
                  // onClick={() => {
                  //   sendEvent?.('click', hit, 'Hit Clicked')
                  // }}
                >
                  <div
                    style={{
                      height: 42,
                      width: 42,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: 'rgba(228, 177, 95, 0.2)',
                      borderRadius: 99,
                      marginLeft: -6,
                      marginTop: -6,
                      marginBottom: -6,
                      color: '#A48757',
                      flexShrink: 0,
                    }}
                  >
                    <LawIcon width={20} />
                  </div>
                  <Stack gap={0}>
                    <Text style={{ flexGrow: 1, lineHeight: 1.3 }} weight={600} size={isFullNormMatch ? 'large' : '1.075em'} maxLines={2}>
                      {hit.title}
                    </Text>
                    <Text style={{ marginTop: 0 }} color='secondary'>{hit.abbreviation && `${hit.abbreviation} · `}{mapping[hit.lawType.toLowerCase()] ?? hit.lawType}</Text>
                  </Stack>
                </Link>
              </Row>
              {index === 0 && result.hits && result.hits.length > 1 && (
                <>
                  <hr style={{ margin: '6px -6px 6px 15px' }} />
                  <Text style={{ marginLeft: 18, marginTop: 12, textTransform: 'uppercase' }} size='small' color='tertiary' weight={500}>
                    Häufig aufgerufen
                  </Text>
                </>
              )}
            </Fragment>
          )
        }

        const isClauseFullMatch = false
        if (!hit.enumeration) return null
        // console.log('hit', hit)
        return (
          // Clause results
          <Fragment key={hit.id}>
            {index === 0 && hit.documentType === 'clause' && (
              // Small "banner" showing the law abbreviation and title for clause results
              <Row asChild gap='6px' style={{ display: 'inline-flex' }}>
                <Link
                  href={path}
                  style={{
                    padding: '9px 15px',
                    color: '#A48757',
                  }}
                >
                  <LawIcon width={16} />
                  <Text color='currentColor' size='0.85rem' weight={550} maxLines={2}>
                    {hit.abbreviation ? `${hit.abbreviation} · ` : ''}{hit.lawTitle}
                  </Text>
                </Link>
              </Row>
            )}
            <Stack gap={index === 0 ? '6px' : '3px'} asChild className={styles.resultItem} title={hit.title}>
              <Link
                href={`${path}/${urlify(hit.enumeration)}`}
                onClick={() => {
                  // sendEvent?.('click', hit, 'Hit Clicked')
                }}
              >
                <Text maxLines={2} style={{ lineHeight: 1.4 }} weight={isClauseFullMatch ? 600 : 500} size={isClauseFullMatch ? 'large' : undefined}>
                  {hit.enumeration}
                  {/* {hit.citation} */}
                  {/* {hit.enumeration && <Snippet style={{ display: 'inline-block', paddingRight: 9 }} highlightedTagName='mark' attribute='enumeration' hit={hit} />} */}
                  {/* {hit.clause?.enumeration && <Snippet style={{ display: 'inline-block', paddingRight: 9 }} highlightedTagName='mark' attribute='clause.enumeration' hit={hit} />} */}
                  {hit.title}
                  {/* {hit.title && (<Snippet highlightedTagName='mark' attribute='title' hit={hit} />)} */}
                  {/* {hit.clause?.title && (<Snippet highlightedTagName='mark' attribute='clause.title' hit={hit} />)} */}
                </Text>

                <Text hyphens weight={300} style={{ opacity: isClauseFullMatch ? 1 : 0.85, lineHeight: 1.4 }} maxLines={isClauseFullMatch ? undefined : 2}>
                  {hit.content}
                  {/* <Snippet highlightedTagName='mark' attribute={hit.content ? 'content' : 'description'} hit={hit} /> */}
                </Text>
              </Link>
            </Stack>
            {isClauseFullMatch && result.hits && result.hits.length > 1 && (
              <hr style={{ margin: '6px -6px 6px 15px' }} />
            )}
          </Fragment>
        )
      })}
    </Stack>
  )
}

export function EntscheidungResult ({ result: res }: { result: DocumentGroupResults }) {
  const result = res.hits?.[0].document
  if (!result) return null

  let path
  try {
    path = getDecisionUrl(result)
  } catch (error) {
    console.error('Error getting URL for EntscheidungResult', error, res)
    return (
      <Text>
        Oh no!
      </Text>
    )
  }
  // console.log('result', result)

  return (
    <Stack
      gap='1px'
      className={`${card} ${!result.normId ? styles.disabled : ''}`}
      style={{ padding: 6 }}
      title={!result.normId ? 'Entscheidung nicht verfügbar' : undefined}
      // onClick={() => {
      // }}
    >
      <Stack asChild gap='9px' className={styles.resultItem}>
        <Link href={path} aria-disabled={!path}>
          <Row gap='15px'>
            <div
              style={{
                height: 42,
                width: 42,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'rgba(228, 177, 95, 0.2)',
                borderRadius: 99,
                marginLeft: -6,
                marginTop: -6,
                marginBottom: -6,
                color: '#A48757',
                flexShrink: 0,
              }}
            >
              <Scale width={20} />
            </div>
            <Stack gap={0}>
              <Heading h={3} style={{ flexGrow: 1 }}>
                {result.Geschaeftszahl}—{[result.institution].flat().join(', ')} Entscheidung
              </Heading>
              <Row gap={0.5} wrap>
                <span style={{ opacity: 0.55 }}>{formatDate(new Date(result.decisionDate).toISOString())}</span>
              </Row>
            </Stack>
          </Row>
          {result.content && (
            <Text maxLines={3}>
              {result.content}
            </Text>
          )}
        </Link>
      </Stack>
      {/* {result._snippetResult?.content?.map?.((item, index) => {
        if (item.content.matchLevel === 'none') {
          return null
        }

        return (
          // eslint-disable-next-line
          <Link key={index} href={`${path}#${item.title.value.toLowerCase().replaceAll(' ', '-')}`} className={styles.resultItem}>
            <Text weight={300} style={{ opacity: 0.85, lineHeight: 1.4 }}>
              <b style={{ fontWeight: 600 }}>{item.title.value}</b><br />
              <span dangerouslySetInnerHTML={{ __html: item.content.value }} />
            </Text>
          </Link>
        )
      })} */}
    </Stack>
  )
}

export function RechtssatzResult ({ result: res }: { result: DocumentGroupResults }) {
  const result = res.hits?.[0].document

  let path
  try {
    path = getDecisionUrl(result)
    // path = getUrl(result.apiPath, { norm: result.normId, lowerCase: false })
  } catch (error) {
    console.error('Error getting URL for RechtssatzResult', error, result)
    return (
      <Text>
        Oh no!
      </Text>
    )
  }

  return (
    <Stack
      gap='1px'
      style={{ padding: 6 }}
      className={card}
      onClick={() => {
        // @ts-expect-error IEntscheidungResult type is wrong
        sendEvent?.('click', result, 'Hit Clicked')
      }}
    >
      <Stack asChild gap='9px' className={styles.resultItem}>
        <Link href={path}>
          <Row gap='15px'>
            <div
              style={{
                height: 42,
                width: 42,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'rgba(228, 177, 95, 0.2)',
                borderRadius: 99,
                marginLeft: -6,
                marginTop: -6,
                marginBottom: -6,
                color: '#A48757',
                flexShrink: 0,
              }}
            >
              <Scale width={20} />
            </div>
            <Stack gap={0}>
              <Heading h={3} style={{ flexGrow: 1 }}>
                {result.Rechtssatznummer}—{[result.institution].flat().join(', ')} Rechtssatz
              </Heading>
              <Row gap={0.5} wrap>
                <span style={{ opacity: 0.55 }}>{formatDate(new Date(result.decisionDate).toISOString())}</span>
              </Row>
            </Stack>
          </Row>
          {result.content && (
            <Text maxLines={3} style={{ opacity: 0.75 }}>
              {result.content}
            </Text>
          )}
        </Link>
      </Stack>
      {/* {result.refs && (
        <Row gap={0.5} wrap style={{ paddingTop: 6, paddingLeft: 12, paddingBottom: 9 }}>
          {result?.norms?.map((norm) => {
            return (
              <DecisionButton key={norm.norm} {...norm} />
            )
          })}
        </Row>
      )} */}
    </Stack>
  )
}

// function EntscheidungenCount ({ count }: { count: number }) {
//   if (!count) return null

//   return (
//     <span style={{ opacity: 0.55 }}>{count} {count > 1 ? 'Entscheidungen' : 'Entscheidung'}</span>
//   )
// }

// function RechtssaetzeCount ({ count }: { count: number }) {
//   if (!count) return null

//   return (
//     <span style={{ opacity: 0.55 }}>{count} {count > 1 ? 'Rechtssätze' : 'Rechtssatz'}</span>
//   )
// }
